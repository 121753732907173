export const routeCodes = {
  NULL_ROUTE: '',
  ENTITY: {
    EMPLOYEE: '/employee',
    COMPANY: '/company',
    SALES_CHANNEL: '/sales-channel',
    CORBAN: '/corban',
    LEGAL_PERSON: '/legal-person',
  },
  MODULES: {
    CEP: '/cep',
    CDC: '/cdc',
    CP: '/cp',
    PJ: '/pj',
    CDV: '/cdc_veiculo',
    CDVPJ: '/cdv_veiculo_pj',
  },
  DEFAULT: {
    PROFILES: '/profiles',
    REGISTER: '/signup',
    LOGIN: '/login',
    PENDING_APPROVE: '/pending-approve',
    TOKEN: '/token',
    VERIFY_STATUS: '/verify-status',
    SEND_DOCUMENTS: '/send-documents',
    SIGN_CONTRACT: '/sign-contract',
    SIGN_PORTABILITY: '/sign-portability',
    FIRST_ACCESS: '/first-access',
    VALIDATE_INVITE: '/validate-invite',
    RECOVERY_PASSWORD: '/recovery-password',
    SIGN_FAILED: '/sign-failed',
    UNAVAILABLE: '/503',
    NOT_FOUND: '/403',
    LGPD: '/lgpd',
    LGPD_MAIL: '/lgpd-mail',
    LGPD_FINISH_REGISTER: '/finish-register',
    LOCATION: '/location',
    TOKEN_SEND: '/token-send',
    FGTS_REGISTER: '/register-fgts',
  },

  EMPLOYEE: {
    DASHBOARD: '/dashboard',
    USER_TERMS: '/user-terms',
    LOCATION: '/location',
    HIRE_CREDIT: '/credit',
    RENEGOTIATE: '/renegotiate',
    CONTRACTS: '/contracts',
    PROPOSALS: '/proposals',
    UPDATE_DATA: '/update-data',
    PORTABILITY: '/portability',
    MY_ACCOUNT: '/my-account',
    INCOME_TAX: '/income-tax',
    AUTOMATIC_REGULATION: '/automatic_regulation',
    CONSIGNED_CARD: '/consigned-card',
  },

  SELF_ATTENDANCE: {
    DASHBOARD: '/dashboard',
    USER_TERMS: '/user-terms',
    LOCATION: '/location',
    HIRE_CREDIT: '/credit',
    UPDATE_DATA: '/update-data',
    MY_ACCOUNT: '/my-account',
    INCOME_TAX: '/income-tax',
  },

  COMPANY: {
    DASHBOARD: '/dashboard',
    EMPLOYEE: '/employee',
    CONTRACT: '/contract',
    USER: '/user',
    REPASS: '/repass',
    COVENANT: '/covenant',
    MY_ACCOUNT: '/my-account',
  },

  SALES_CHANNEL: {
    SALES_CHANNEL: '/sales-channel',
    LOCATION: '/location',
    HIRE_CREDIT: '/credit',
    PROPOSAL: '/proposal',
    CONTRACTS: '/contracts',
    FGTS_SIMULATION: '/fgts-simulation',
    FGTS_REQUEST: '/fgts-request',
    OPERATORS: '/operators',
    RENEGOTIATE: '/renegotiate',
    RENEGOTIATE_CDV: '/renegotiate-cdv',
    CDC_VEHICLES: '/cdc-vehicles',
    CANCELED_CAIXA: '/canceled_caixa',
  },

  LEGAL_PERSON: {
    LEGAL_PERSON: '/legal-person',
    SALES_CHANNEL: '/sales-channel',
    LOCATION: '/location',
    HIRE_CREDIT: '/credit',
    HIRE_CREDIT_WORKING_CAPITAL: '/working-capital',
    PROPOSAL: '/proposal',
    CONTRACTS: '/contracts',
    OPERATORS: '/operators',
    RENEGOTIATE: '/renegotiate',
  },

  CORBAN: {
    HIRE_CREDIT: '/credit',
    CONSIGNED_CARD: '/consigned-card',
    ASSOCIATE_CHANNELS: '/associate-channels',
    OPERATORS: '/operators',
    RENEGOTIATE: '/renegotiate',
    LOCATION: '/location',
    PORTABILITY: '/portability',
    CONTRACT: '/contract',
    PROPOSALS: '/proposals',
    LGPD: '/lgpd',
    REGISTER_CLIENT: '/register-client',
  },

  FEEDBACK: {
    PROPOSAL_SENT: '/proposal-sent',
    SC_PROPOSAL_SENT: '/sc-proposal-sent',
    PORTABILITY_REQUEST: '/portability-request',
  },

  FGTS_REGISTER: {
    TOKEN_SEND: '/token-send',
    TOKEN: '/token',
  },
}

export const subRouteCodes = {
  VIEW: '/view',
  NEW: '/new',
  EDIT: '/edit',
  LIST: '/list',

  DEFAULT: {
    PROFILES: {
      USER_TERM: '/user-term',
      LOGIN: '/auth',
    },
    TOKEN: {
      EMAIL: '/email',
      SMS: '/sms',
      VALIDATE: '/validate',
    },
    LGPD: {
      VALIDATE: '/validate',
    },
    FIRST_ACCESS: {
      EMAIL: {
        DEFAULT: '/mail',
        VALIDATE: '/validate',
      },
      USER_TERMS: '/user-terms',
      TELEPHONE: '/telephone',
      VALIDATE_PHONE: '/validate-phone',
      INVITE: '/invite',
    },
    VALIDATE_INVITE: {
      TOKEN: '/token',
      PASSWORD: '/password',
    },
    RECOVERY_PASSWORD: {
      CONFIRM_TOKEN: '/confirm-token',
      PASSWORD: '/change-password',
    },
    FGTS_REGISTER: {
      VALIDATE: '/validate',
      SEND_TOKEN: '/send-token',
    },
  },

  EMPLOYEE: {
    HIRE_CREDIT: {
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
      STATUS: '/status',
      SIGN: '/sign',
    },
    CONSIGNED_CARD: {
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
      STATUS: '/status',
      SIGN: '/sign',
    },
    RENEGOTIATE: {
      SIMULATE: '/simulate',
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
    },
    PORTABILITY: {
      SIMULATE: '/simulate',
      SIGN: '/sign',
      RESUME: '/resume',
    },
    MY_ACCOUNT: {
      CHANGE_MAIL: '/change-mail',
      CHANGE_PASSWORD: '/change-password',
      CHANGE_PHONE: '/change-phone',
      TOKEN: '/token',
    },
  },

  SELF_ATTENDANCE: {
    HIRE_CREDIT: {
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
      STATUS: '/status',
      SIGN: '/sign',
    },
    RENEGOTIATE: {
      SIMULATE: '/simulate',
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
    },
    PORTABILITY: {
      SIMULATE: '/simulate',
    },
    MY_ACCOUNT: {
      CHANGE_MAIL: '/change-mail',
      CHANGE_PASSWORD: '/change-password',
      CHANGE_PHONE: '/change-phone',
      TOKEN: '/token',
    },
  },

  COMPANY: {
    DASHBOARD: {
      DETAILS: '/details',
    },

    EMPLOYEE: {
      IMPORT: '/import',
      IMPORT_CSV: '/import-csv',
      LGPD_EMPLOYEES: '/lgpd-employees',
      TRANSFER: '/transfer',
      DEMISSION: '/demission',
      DEMISSION_LOTE: '/demission-lote',
      DEMISSION_LOTE_VIEW: 'demission-lote',
      DEMISSION_LOTE_V2: '/demission-lote-v2',
      DEMISSION_LOTE_VIEW_V2: 'demission-lote-v2',
    },

    CONTRACT: {
      PENDING: '/pending',
      PENDING_PORTABILITY: '/pending-portability',
      DENIED: '/denied',
      EXPIRATED: '/expirated',
      APPROVED: '/approved',
      CANCELATED: '/cancelated',
      FINISHED: '/finished',
      REVERSED: '/reversed',
    },

    PROPOSALS: {
      PENDING: '/pending',
      PENDING_PORTABILITY: '/pending-portability',
      EXPIRATED: '/expirated',
      CREDIT_TABLE: '/credit_table',
    },

    REPASS: {
      DETAILS: '/details',
      DISCOUNT: {
        DEFAULT: '/discount',
        TRANSFER: '/transfer',
      },
    },

    MY_ACCOUNT: {
      CHANGE_MAIL: '/change-mail',
      CHANGE_PASSWORD: '/change-password',
      CHANGE_PHONE: '/change-phone',
      TOKEN: '/token',
    },
  },

  FGTS_REGISTER: {
    TOKEN: {
      VALIDATE: '/validate',
    },
  },

  SALES_CHANNEL: {
    HIRE_CREDIT: {
      VERIFY_IDENTITY: '/verify',
      REGISTER: '/register',
      RESUME: '/resume',
      SIMULATE: '/simulate',
    },

    RENEGOTIATE: {
      SIMULATE: '/simulate',
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
    },

    RENEGOTIATE_CDV: {
      SIMULATE: '/simulate',
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
    },

    PROPOSALS: {
      APPROVED: '/approved',
      DENIED: '/denied',
      CANCELATED: '/cancelated',
      PENDING_DOC: '/pending-documentation',
      CREDIT_TABLE: '/credit-table',
    },

    CONTRACTS: {
      CANCELATED: '/cancelated',
      PENDING_SIGN: '/pending-sign',
      EXPIRATED: '/expirated',
      REVERSED: '/reversed',
      PENDING_TED: '/pending-ted',
    },

    FGTS_REQUEST: {
      FORM_DATA_CLIENT: '/confirm-data',
    },

    CDC_VEHICLES: {
      FORM_DATA_VEHICLES: '/confirm-data-vehicles',
      FORM_DATA_CLIENT: '/confirm-data',
      RESUME: '/resume',
    },
  },

  LEGAL_PERSON: {
    HIRE_CREDIT: {
      VERIFY_IDENTITY: '/verify',
      REGISTER: '/register',
      RESUME: '/resume',
    },

    HIRE_CREDIT_WORKING_CAPITAL: {
      VERIFY_IDENTITY: '/verify',
      REGISTER: '/register',
      RESUME: '/resume',
    },

    RENEGOTIATE: {
      SIMULATE: '/simulate',
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
    },

    PROPOSALS: {
      APPROVED: '/approved',
      DENIED: '/denied',
      CANCELATED: '/cancelated',
      PENDING_DOC: '/pending-documentation',
    },

    CONTRACTS: {
      CANCELATED: '/cancelated',
      PENDING_SIGN: '/pending-sign',
      EXPIRATED: '/expirated',
      REVERSED: '/reversed',
      PENDING_TED: '/pending-ted',
    },
  },

  CORBAN: {
    HIRE_CREDIT: {
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
      STATUS: '/status',
      SIMULATE: '/simulate',
      VERIFY: '/verify-identity',
      VERIFY_TOKEN: '/verify-token',
    },
    CONSIGNED_CARD: {
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
      STATUS: '/status',
      SIMULATE: '/simulate',
      VERIFY: '/verify-identity',
      VERIFY_TOKEN: '/verify-token',
    },
    RENEGOTIATE: {
      SIMULATE: '/simulate',
      CONFIRM_DATA: '/confirm-data',
      RESUME: '/resume',
      VERIFY_TOKEN: '/verify-token',
      LIST: '/list',
    },
    PORTABILITY: {
      SIMULATE: '/simulate',
      SENT: '/sent',
      VERIFY_TOKEN: '/verify-token',
    },
    VERIFY_IDENTITY: '/verify-identity',
    CONTRACTS: {
      ALL: '/all',
      ACTIVE: '/active',
      FINISHED: '/finished',
      CANCELATED: '/cancelated',
      REVERSED: '/reversed',
      PENDING_SIGN: '/pending-sign',
      PENDING_AVERBATION: '/pending-averbation',
      PENDING_TED: '/pending-ted',
      DENIED_AVERBATION: '/denied-averbation',
      GENERATE_SIGN_LINK: '/generate-sign',
    },
    PROPOSALS: {
      ALL: '/all',
      CREDIT_DESK: '/credit-desk',
      PORTABILITIES_PENDING_SIGN: '/portabilities-pending-sign',
      PENDING_SIGN: '/pending-sign',
      CANCELATED: '/cancelated',
      DENIED_PROPOSAL: '/denied-proposal',
      GENERATE_SIGN_LINK: '/generate-sign',
      PORTABILITIES: '/portabilities',
    },
  },
}
