import React, { useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useCore, useApi, useErrors } from 'hooks'
import { IEntity, ICookiesUser, ILayout, ENTITY_ENUM } from 'interfaces'
import PATHS from 'constants/paths'
import DefaultEntity from 'app/Default'
import FeedbackEntity from 'app/Feedback'

import { getCookies } from 'components/Provider/CookiesProvider'
import { CORBAN_SUBMODULES } from 'interfaces/submodules'

const ModuleGuard: React.FC<ILayout> = ({ children }) => {
  const { apiRequest, isTokenExpired } = useApi()
  const { setEntity, entity, auth } = useCore()
  const { error, setError } = useErrors()
  const history = useHistory()
  const user: ICookiesUser = useMemo(() => getCookies('CFU'), [])
  const userFunction = user.userFunction || ''

  useEffect(() => {
    const getUserFunction = async () => {
      const response = await apiRequest({
        path: PATHS.USER_FUNCTION,
        method: 'GET',
        pathParams: {
          userFunction,
        },
      })
      if (response) {
        const isCorbanPubl = entity.module?.subModule === CORBAN_SUBMODULES.CPUBLICO
        const isEmployee = response.entidade.tipo === ENTITY_ENUM.EMPLOYEE
        setEntity(
          (prevState) =>
            ({
              ...prevState,
              funcao: response,
              isCompanyPrivate: isEmployee
                ? response.entidade.informacoes?.empresa?.empresa_privada
                : isCorbanPubl
                ? false
                : true,
            } as IEntity)
        )
      }
      return response
    }
    if (userFunction && !entity.funcao && entity.id !== DefaultEntity.id) {
      getUserFunction()
    }
  }, [userFunction, apiRequest, setEntity, entity, isTokenExpired, setError])

  useEffect(() => {
    if (entity.name !== DefaultEntity.name && auth.token_jwt) {
      if (Number(error.code) === 403) {
        history.push(`${FeedbackEntity.path}${FeedbackEntity.route.NOT_FOUND.path}`)
      } else if (Number(error.code) === 500) {
        history.push(`${FeedbackEntity.path}${FeedbackEntity.route.ERROR.path}`)
      } else if (Number(error.code) === 404) {
        history.push('/404')
      } else if (Number(error.code) === 503) {
        history.push(`${FeedbackEntity.path}${FeedbackEntity.route.UNAVAILABLE.path}`)
      }
    }
  }, [error.code, history, entity, auth])

  return <>{children}</>
}

export default ModuleGuard
